import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";
import { ModelRow } from "./model.type";

export function getPublicModels(): Promise<ModelRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.model;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData(url, request);
}
