import clsx from "clsx";
import { useState } from "react";
import { PublicToolRow, ToolRow } from "../../api/tool.type";
import { useAgentAgentToolContext } from "../agent/hooks/AgentAgentToolContext";
import { useAgentToolContext } from "../fabrk/hooks/AgentToolContext";
import { useAuthContext } from "../fabrk/hooks/AuthContext";
import GenericDialog from "../shared/dialog/GenericDialog";
import { useLoadUsersTools } from "../tools/hooks/useLoadUserTools";
import { useLoadPublicTools } from "../tools/hooks/usePublicTools";
import AddAgentToolForm from "./AddAgentToolForm";
import AddEvaluationsForm from "./AddEvaluationsForm";
import AgentToolItemFooter from "./AgentToolItemFooter";
import AgentToolItemHeader from "./AgentToolItemHeader";
import ToolGridHeaderButtons from "./ToolGridHeaderButtons";
import ToolItemFooter from "./ToolItemFooter";
import ToolItemHeader from "./ToolItemHeader";

export default function ToolGrid() {
  const { agentTools } = useAgentToolContext();

  const { publicTools } = useLoadPublicTools();

  const { companyId } = useAuthContext();

  const { agentAgentTools } = useAgentAgentToolContext();

  const { usersTools } = useLoadUsersTools(companyId);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");

  return (
    <div className="flex w-full flex-col items-center">
      <ToolGridHeaderButtons
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        setDialogTitle={setDialogTitle}
      />
      {agentAgentTools && agentAgentTools.length > 0 && (
        <div className="w-full p-2">
          <p className="mx-1 my-4 w-full px-2 py-1 text-center text-xl">
            Agents added to this agent as tools:
          </p>
          <ul className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
            {agentAgentTools?.map((aat) => {
              return (
                <li
                  key={aat.id}
                  className="md:min-h-md col-span-1 mx-auto flex w-full flex-col justify-between divide-y divide-skin-hover rounded-lg border-2 border-skin-hover bg-skin-hover shadow"
                >
                  <AgentToolItemHeader
                    icon={
                      <div className="h-6 w-6">
                        <img
                          src={
                            aat?.childAgent?.image_path ||
                            "/images/woven-tech.png"
                          }
                          alt="agent icon"
                        />
                      </div>
                    }
                    name={aat.childAgent?.name as string}
                    description={aat.childAgent?.goal as string}
                  />
                  <AgentToolItemFooter aat={aat} />
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {agentTools && agentTools.length > 0 && (
        <p className="mx-1 my-4 w-fit px-2 py-1 text-center text-xl">
          Tools added to this agent:
        </p>
      )}
      <ul className="grid w-full grid-cols-1 gap-4 p-2 md:grid-cols-2">
        {agentTools &&
          agentTools.length > 0 &&
          agentTools.map((tool) => {
            return (
              <li
                key={tool.tool.id}
                className="md:min-h-md col-span-1 mx-auto flex w-full flex-col justify-between divide-y divide-skin-hover rounded-lg border-2 border-skin-hover bg-skin-hover shadow"
              >
                <ToolItemHeader tool={tool.tool} />
                <ToolItemFooter tool={tool.tool} />
              </li>
            );
          })}
      </ul>
      <p className="mx-1 my-4 rounded-lg text-center text-xl">Your Tools:</p>
      <ul className="grid w-full grid-cols-1 gap-4 p-2 md:grid-cols-2">
        {usersTools &&
          usersTools.length > 0 &&
          usersTools
            .filter(
              (t) =>
                !agentTools?.some((at) => {
                  return at.tool.id === t.id;
                }),
            )
            .map((tool: PublicToolRow) => {
              return (
                <li
                  key={tool.id}
                  className="md:min-h-md col-span-1 mx-auto flex w-full flex-col justify-between divide-y divide-skin-hover rounded-lg border-2 border-skin-hover bg-skin-fill shadow"
                >
                  <ToolItemHeader tool={tool} />
                  <ToolItemFooter tool={tool} />
                </li>
              );
            })}
      </ul>

      <p className="mx-1 my-4 rounded-lg text-center text-xl">
        Publicly Available Tools:
      </p>

      <ul className="grid w-full grid-cols-1 gap-4 p-2 md:grid-cols-2">
        {publicTools &&
          publicTools
            .filter((tool) => {
              return !agentTools?.some(
                (agentTool) => agentTool.tool.id === tool.id,
              );
            })
            .map((tool: ToolRow) => {
              return (
                <li
                  key={tool.name}
                  className={clsx(
                    "min-h-md col-span-1 mx-auto flex w-full flex-col justify-between rounded-lg border-2 border-skin-hover bg-skin-fill shadow",
                  )}
                >
                  <ToolItemHeader tool={tool} />
                  <ToolItemFooter tool={tool} />
                </li>
              );
            })}
      </ul>
      <GenericDialog
        title={dialogTitle}
        open={dialogOpen}
        setOpen={setDialogOpen}
      >
        {dialogTitle === "Add Agent Tool" && (
          <AddAgentToolForm setDialogOpen={setDialogOpen} />
        )}

        {dialogTitle === "Add Evaluations" && (
          <AddEvaluationsForm setDialogOpen={setDialogOpen} />
        )}
      </GenericDialog>
    </div>
  );
}
