import { ShareArrow, UserGroup } from "../shared/icons/icons";

function ToolHeaderPills({
  runCount,
  forks,
}: {
  runCount?: number;
  forks?: number;
}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex h-fit flex-row items-center justify-evenly gap-1 rounded-md bg-skin-fill p-1 text-skin-base">
        <UserGroup className="h-3 w-3" />
        <p className="text-xs">Run Count</p>
        <p className="text-xs">{runCount || 0}</p>
      </div>
      <div className="flex h-fit flex-row items-center justify-evenly gap-1 rounded-md bg-skin-fill p-1 text-skin-base">
        <ShareArrow className="h-3 w-3" />
        <p className="text-xs">Forks</p>
        <p className="text-xs">{forks || 0}</p>
      </div>
    </div>
  );
}

export default ToolHeaderPills;
