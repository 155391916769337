/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import SubmitButton from "../shared/buttons/SubmitButton";
import FabrkMenuDivider from "../shared/dividers/FabrkMenuDivider";
import { useToolContext } from "./hooks/ToolContext";

function ViewToolProductionContainer() {
  const { tool, prodTool, getProdTool, createProdTool } = useToolContext();

  function handleCreateProdTool() {
    const values = {
      name: tool?.name as string,
      description: tool?.description as string,
      function_name: tool?.function_name as string,
      type: tool?.type as string,
      context: tool?.context as string,
      parameters: tool?.parameters as Record<string, any>,
      slug: tool?.slug as string,
      image_path: tool?.image_path as string,
    };

    createProdTool(values);
  }

  useEffect(() => {
    getProdTool(tool?.function_name as string);
  }, [tool]);

  return (
    <div className="max-w-2xl">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-4">
          <p className="mt-1 max-w-2xl text-sm leading-6 text-skin-base">
            {prodTool
              ? "Production settings for this tool"
              : "Create production settings for this tool"}
          </p>
        </div>
      </div>
      <FabrkMenuDivider />
      {prodTool ? (
        <div className="mt-4 flex flex-col gap-4">
          <p>{prodTool?.name}</p>
          <p>{prodTool?.description}</p>
          <p>Id: {prodTool?.id}</p>
          <p>Function name: {prodTool?.function_name}</p>
          <p>Type: {prodTool?.type}</p>
          <p>Context: {prodTool?.context}</p>
          <p>Parameters: {JSON.stringify(prodTool?.parameters)}</p>
        </div>
      ) : (
        <SubmitButton type="submit" onClick={handleCreateProdTool}>
          Add tool to prod
        </SubmitButton>
      )}
      {/* {editContext && isCompanyTool ? (
        <div className="mt-4 flex flex-col gap-4">
          <TextArea value={toolContext} setValue={setToolContext} />
          <div className="flex justify-end">
         
          </div>
        </div>
      ) : (
        <p className="mt-4">{tool?.context || "No context listed"}</p>
      )} */}
    </div>
  );
}

export default ViewToolProductionContainer;
