import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { PublicToolRow, ToolCreate, ToolRow } from "./tool.type";

export function getAvailableTools(
  companyId?: string,
): Promise<PublicToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.companyTool +
    "/company/" +
    companyId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<PublicToolRow[]>(url, request);
}

export function createCompanyTool(values: ToolCreate): Promise<ToolRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.companyTool;

  const request = {
    method: "POST",
    body: JSON.stringify({ ...values }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ToolRow>(url, request);
}
