import { mapTypeToLabel } from "../../../api/agent.type";
import FabrkMenuDivider from "../../shared/dividers/FabrkMenuDivider";
import IconButton from "../../shared/buttons/IconButton";
import BaseChip from "../../shared/chip/BaseChip";
import { CopyToClipboardIconButton } from "../../shared/forms/CopyToClipboard";
import { EditIcon } from "../../shared/icons/icons";
import ShowMoreParagraph from "../../shared/typography/ShowMoreParagraph";
import {
  DataValueBase,
  LabelBase,
} from "../../shared/typography/TypographyBase";
import { useAgentContext } from "../hooks/AgentContext";
import { agentTypeIcons } from "./agentTypeToIconMap";
import EditAgentName from "./EditAgentName";

function ViewAgentHeaderDetails({
  isEdit,
  setIsEdit,
}: {
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { activeAgent } = useAgentContext();
  return (
    <div className="w-full leading-7 text-skin-base/80">
      <div className="flex flex-row justify-between">
        <p className="font-semibold leading-7 text-skin-muted/80">
          Agent Details
        </p>

        <IconButton
          onClick={() => {
            setIsEdit(!isEdit);
          }}
        >
          <EditIcon className="h-5 w-5" />
        </IconButton>
      </div>
      <FabrkMenuDivider />
      {isEdit ? (
        <EditAgentName setIsEdit={setIsEdit} />
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center justify-between">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-skin-base sm:text-4xl">
              {activeAgent?.name}
            </h1>

            {activeAgent?.type && (
              <BaseChip>
                <div className="flex w-full flex-row gap-2">
                  <div>{agentTypeIcons[activeAgent.type]}</div>
                  <div>{mapTypeToLabel(activeAgent?.type)}</div>
                </div>
              </BaseChip>
            )}
          </div>
          <div className="flex max-w-xl flex-col gap-4">
            <div>
              <LabelBase>Model</LabelBase>
              <div className="flex w-fit flex-row items-center gap-2">
                <DataValueBase>{activeAgent?.model?.name}</DataValueBase>
              </div>
            </div>
            <div>
              <LabelBase>Slug</LabelBase>
              <div className="flex w-fit flex-row items-center gap-2">
                <DataValueBase>{activeAgent?.slug}</DataValueBase>
                <CopyToClipboardIconButton text={activeAgent?.slug as string} />
              </div>
            </div>
            <div>
              <LabelBase>Explanation</LabelBase>
              <DataValueBase>{activeAgent?.goal}</DataValueBase>
            </div>
            <div>
              <LabelBase>ID</LabelBase>
              <div className="flex w-fit flex-row items-center gap-2">
                <DataValueBase>{activeAgent?.id}</DataValueBase>
                <CopyToClipboardIconButton text={activeAgent?.id as string} />
              </div>
            </div>
          </div>
          <div className="max-w-xl">
            <div className="flex flex-row gap-2">
              <LabelBase>Instructions</LabelBase>
              <LabelBase>
                Version: {activeAgent?.system_prompt?.version}
              </LabelBase>
            </div>
            <ShowMoreParagraph
              text={activeAgent?.system_prompt?.text as string}
              maxLength={250}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewAgentHeaderDetails;
