/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowRightIcon,
  PlusCircleIcon,
  StopCircleIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useAgentContext } from "../../../agent/hooks/AgentContext";
import { useCompanyContext } from "../../../fabrk/hooks/CompanyContext";
import { useMessageContext } from "../../../fabrk/hooks/MessageContext";
import { useEventStreaming } from "../../../message/StreamMessageContext";
import IconButton from "../../buttons/IconButton";
import LargeChip from "../../chip/LargeChip";
import VoiceRecorder from "../../voice/VoiceRecorder";
import { useVoiceContext } from "../../voice/VoiceRecorderContext";
import FileUploadInput from "../FileUploadInput";
import { useFileUploadContext } from "../hooks/fileUploadContext";
import { MainTextInput } from "../input/InputBase";

const fileTypes = [
  ".csv",
  ".xls",
  ".xlsx",
  ".pptx",
  ".pdf",
  ".docx",
  ".doc",
  ".txt",
  ".md",
  ".json",
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".webp",
];

function TextAreaWithButtons() {
  const {
    file,
    hiddenFileInput,
    handleChange,
    uploadOneFile,
    createdFileStore,
    handleOpenFileSelect,
    setFileName,
  } = useFileUploadContext();

  const { company } = useCompanyContext();
  const { activeAgent } = useAgentContext();
  const { isRecording, setAudioContent } = useVoiceContext();

  const {
    handleCreateMessage,
    newMessage,
    getUpdatedMessages,
    setNewMessage,
    counter,
    setCounter,
    persona,
    loading,
  } = useMessageContext();

  const [inputValue, setInputValue] = useState("");

  const {
    startStreaming,
    streaming,
    personaStreaming,
    personaText,
    setPersonaText,
  } = useEventStreaming();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleCreateMessage({
      content: inputValue,
      fileStoreId: createdFileStore?.id,
      agentId: activeAgent?.id as string,
    });
    setInputValue("");
    setFileName("");
  }

  const [ellipsis, setEllipsis] = useState("");

  useEffect(() => {
    if (loading) {
      // Start the ellipsis effect if loading
      const intervalId = setInterval(() => {
        setEllipsis((prev) => (prev.length < 3 ? prev + "." : ""));
      }, 500);

      // Clear the interval when loading stops
      return () => clearInterval(intervalId);
    } else {
      setEllipsis(""); // Reset ellipsis if not loading
    }
  }, [loading]);

  useEffect(() => {
    if (newMessage) {
      startStreaming(newMessage.id);
      setPersonaText("");
      setNewMessage(undefined);
    }
  }, [newMessage]);

  useEffect(() => {
    if (!streaming && !newMessage) {
      setTimeout(() => {
        getUpdatedMessages();
        setAudioContent(null);
      }, 2000);
    }
  }, [streaming]);

  useEffect(() => {
    if (personaStreaming) {
      setCounter(counter - 1);
    } else {
      if (personaText) {
        handleCreateMessage({
          content: personaText,
          agentId: activeAgent?.id as string,
        });
      }
    }
  }, [personaStreaming]);

  useEffect(() => {
    if (file) {
      setFileName(file.name);
      uploadOneFile({
        file,
        additionalData: {
          companyId: company?.id,
          location: "images",
          agentId: activeAgent?.id as string,
        },
      });
    }
  }, [file]);

  return (
    <>
      {counter > 0 && persona ? (
        <div className="flex flex-col items-center gap-4">
          <LargeChip>Remaining Cycles: {counter}</LargeChip>
          <IconButton onClick={() => setCounter(0)}>
            <StopCircleIcon className="h-6 w-6 text-skin-accent" /> Stop Cycles
          </IconButton>
        </div>
      ) : (
        <div className="group flex w-3/4 max-w-4xl rounded-md border-2 border-skin-muted shadow-sm focus-within:border-2 focus-within:border-skin-accent">
          <MainTextInput
            value={inputValue}
            setValue={setInputValue}
            handleSubmit={handleSubmit}
            disabled={loading}
            placeholder={
              isRecording
                ? "Recording..."
                : loading
                  ? `Agent responding${ellipsis}`
                  : activeAgent?.name
                    ? `Send a message to ${activeAgent?.name}`
                    : "Send a message"
            }
          />

          <div className="relative -ml-px inline-flex items-center bg-skin-hover">
            <VoiceRecorder />
          </div>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md bg-skin-hover p-1 text-sm font-semibold text-skin-base shadow-sm ring-inset hover:bg-skin-hover"
            onClick={(e) => {
              e.preventDefault();
              handleOpenFileSelect(e);
            }}
          >
            <PlusCircleIcon className="h-6 w-6 text-skin-muted" />
          </button>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md bg-skin-hover p-1 text-sm font-semibold text-skin-base shadow-sm ring-inset hover:bg-skin-hover"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(e as any);
            }}
          >
            <ArrowRightIcon className="h-6 w-6 rounded-full text-skin-muted" />
          </button>
          <FileUploadInput
            handleChange={handleChange}
            hiddenFileInput={hiddenFileInput}
            accept={fileTypes.join(",")}
            multiple={false}
          />
        </div>
      )}
    </>
  );
}

export default TextAreaWithButtons;
