import { ClientRow } from "./client.type";
import { SmsMetadataRow } from "./smsMetadata.type";

export type TestConfig = {
  lineItems: string;
  date: string;
  time: string;
  totalAmount: string;
};

export enum MessageRoleType {
  user = "user",
  assistant = "assistant",
}

export type MessageRow = {
  content: string;
  created_at: string;
  id: string;
  media_url?: string | null;
  role: MessageRoleType;
  twilio_account_sid?: string;
  twilio_sid?: string;
  agent_id?: string;
  company_id?: string;
  tag?: string[];
  generated_by?: string;
  client?: ClientRow;
  client_id?: string;
  client_agent_id?: string;
  audio_url?: string;
  sms_metadata?: SmsMetadataRow[];
};

export type ConversationsOverview = {
  totalConversation: string;
  totalUserConversations: string;
};

export type CreateMessage = {
  content: string;
  agentId: string;
  source: string;
  companyId?: string;
  clientId?: string;
  mediaUrl?: string;
  threadId?: string;
  fileStoreId?: string;
  actionId?: string;
  role?: MessageRoleType;
  clientAgentId?: string;
};

export type SendFabrkRequest = {
  agentId: string;
  clientId: string;
  phone?: string;
  email?: string;
};

export type FunctionCall = {
  name: string;
  arguments: string;
};

export type ToolCall = {
  id: string;
  type: string;
  function: FunctionCall;
};

export type ChatResponse = {
  response: string | null;
  intervalMessages: ChatMessage[];
};

export enum ChatUserType {
  system = "system",
  user = "user",
  assistant = "assistant",
  tool = "tool",
  function = "function",
}

export interface ChatMessage {
  role: ChatUserType;
  content: string;
  media_url?: string;
  tool_call_id?: string;
  tool_calls?: ToolCall[];
}
