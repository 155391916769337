import { PublicToolRow } from "../../api/tool.type";
import DiscoverListItemHeader from "../agent/discover/DiscoverListItemHeader";
import { DiscoverGridItem } from "../shared/containers/Containers";
import ToolHeaderPills from "./ToolHeaderPills";

function DiscoverToolsListItem({
  tool,
  children,
}: {
  tool: PublicToolRow;
  children?: React.ReactNode;
}) {
  const { name, created_at, image_path, id, run_count, tool_fork } = tool;

  return (
    <DiscoverGridItem>
      <DiscoverListItemHeader
        link={`/tool/${id}`}
        createdAt={created_at}
        name={name}
        imagePath={image_path}
      >
        <ToolHeaderPills runCount={run_count || 0} forks={tool_fork || 0} />
      </DiscoverListItemHeader>
      {children}
    </DiscoverGridItem>
  );
}

export default DiscoverToolsListItem;
