import { setHeaderOptions } from "./apiHelpers";

import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";
import { GetRealTimeTokenParams } from "./realtime.types";

type RealTimeTokenResponse = {
  client_secret: { value: string };
};

const realTimeUrl = "https://api.openai.com/v1/realtime";
const model = "gpt-4o-realtime-preview-2024-12-17";

export const getRealTimeToken = async ({
  voice,
  agentId,
  clientAgentId,
}: GetRealTimeTokenParams): Promise<RealTimeTokenResponse> => {
  const slug =
    process.env.REACT_APP_API_URL + ApiEndpoints.message + "/realtime/token";

  const options = await setHeaderOptions({
    method: "POST",
    body: JSON.stringify({
      voice,
      agentId,
      clientAgentId,
    }),
  });

  const data = await fetchData<RealTimeTokenResponse>(slug, options);
  return data;
};

export const getSdpResponse = async ({
  offer,
  clientSecret,
}: {
  offer: any;
  clientSecret: string;
}): Promise<any> => {
  // Send offer SDP to your realtime endpoint.
  const sdpResponse = await fetch(`${realTimeUrl}?model=${model}`, {
    method: "POST",
    body: offer.sdp,
    headers: {
      Authorization: `Bearer ${clientSecret}`,
      "Content-Type": "application/sdp",
    },
  });

  return sdpResponse;
};

export const handleSendFunctionCall = async ({
  toolName,
  toolArgs,
  clientAgentId,
}: {
  toolName: string;
  toolArgs: any;
  clientAgentId: string;
}): Promise<any> => {
  try {
    const slug =
      process.env.REACT_APP_API_URL + ApiEndpoints.tool + "/realtime/tool-call";

    const options = await setHeaderOptions({
      method: "POST",
      body: JSON.stringify({
        toolName,
        toolArgs,
        clientAgentId,
      }),
    });

    const data = await fetchData<RealTimeTokenResponse>(slug, options);
    return data;
  } catch (error) {
    return {
      error: "Error sending function call:",
      message: error,
    };
  }
};
