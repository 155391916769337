import { useQuery } from "react-query";
import { getPublicModels } from "../../../api/model.api";

export function useLoadPublicModels() {
  const {
    data: publicModels,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "publicModels",
    queryFn: () => getPublicModels(),
  });

  return {
    isLoading,
    publicModels,
    error,
    refetch,
  };
}
