/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useRef, useState } from "react";
import {
  getRealTimeToken,
  getSdpResponse,
  handleSendFunctionCall,
} from "../../../api/realtime.api";
import {
  GetRealTimeTokenParams,
  RealTimeEvent,
} from "../../../api/realtime.types";
import { useMessageContext } from "../../fabrk/hooks/MessageContext";
import { MessageRoleType } from "../../../api/message.type";

export const useRealtimeChat = () => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);
  const { handleCreateMessageFromRealtimeTranscript } = useMessageContext();

  // Store references to clean up later.
  const pcRef = useRef<RTCPeerConnection | null>(null);
  const localStreamRef = useRef<MediaStream | null>(null);

  const startVoiceChat = useCallback(
    async ({ voice, agentId, clientAgentId }: GetRealTimeTokenParams) => {
      try {
        setIsConnecting(true);
        setError(null);

        const data = await getRealTimeToken({
          voice,
          agentId,
          clientAgentId,
        });

        // Create peer connection with a basic ICE configuration.
        const pc = new RTCPeerConnection();
        pcRef.current = pc;

        const audioEl = document.createElement("audio");
        audioEl.autoplay = true;
        pc.ontrack = (e) => (audioEl.srcObject = e.streams[0]);

        // Add local audio track for microphone input in the browser
        const ms = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        pc.addTrack(ms.getTracks()[0]);
        localStreamRef.current = ms;

        const dc = pc.createDataChannel("oai-events");

        const handleEvent = async (e: any) => {
          const serverEvent: RealTimeEvent = JSON.parse(e.data);

          if (
            serverEvent.type ===
              "conversation.item.input_audio_transcription.completed" &&
            serverEvent.transcript
          ) {
            handleCreateMessageFromRealtimeTranscript({
              content: serverEvent.transcript,
              agentId,
              role: MessageRoleType.user,
              clientAgentId,
            });
          }

          if (
            serverEvent.type === "response.done" &&
            serverEvent.response.output &&
            serverEvent.response.output[0]
          ) {
            if (serverEvent.response.output[0].content) {
              const content = serverEvent.response.output[0].content;

              if (content[0].transcript) {
                handleCreateMessageFromRealtimeTranscript({
                  content: content[0].transcript,
                  agentId,
                  role: serverEvent.response.output[0].role,
                  clientAgentId,
                });
              }
            }
          }

          if (serverEvent.type === "response.function_call_arguments.done") {
            if (serverEvent.arguments) {
              const toolArgs = serverEvent.arguments;

              const toolName = serverEvent.name;

              const toolCallResponse = await handleSendFunctionCall({
                toolName: toolName as string,
                toolArgs,
                clientAgentId,
              });

              const response = {
                type: "conversation.item.create",
                item: {
                  type: "function_call_output",
                  call_id: serverEvent.call_id,
                  output: JSON.stringify(toolCallResponse),
                },
              };

              // Create a new conversation item with the tool call response.
              dc.send(JSON.stringify(response));

              setTimeout(() => {
                const createResponse = { type: "response.create" };
                dc.send(JSON.stringify(createResponse));
              }, 50);
            }
          }
        };

        // Set up a data channel for custom messages.
        dc.addEventListener("message", (e) => {
          handleEvent(e);
        });

        // Create an offer, set local description, and send it to your server.
        const offer = await pc.createOffer({});
        await pc.setLocalDescription(offer);

        const sdpResponse = await getSdpResponse({
          offer,
          clientSecret: data?.client_secret?.value,
        });

        const answer: RTCSessionDescriptionInit = {
          type: "answer" as RTCSdpType,
          sdp: await sdpResponse.text(),
        };

        await pc.setRemoteDescription(answer);
        setIsActive(true);
      } catch (err) {
        console.log("Voice chat error:", err);
      } finally {
        setIsConnecting(false);
      }
    },
    [],
  );

  const stopVoiceChat = useCallback(() => {
    // Close the peer connection if it exists.
    if (pcRef.current) {
      pcRef.current.close();
      pcRef.current = null;
    }
    // Stop all tracks on the local media stream.
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => track.stop());
      localStreamRef.current = null;
    }
    setIsActive(false);
  }, []);

  return {
    startVoiceChat,
    stopVoiceChat,
    isConnecting,
    isActive,
    error,
  };
};
