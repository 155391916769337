import { MicrophoneIcon } from "@heroicons/react/24/outline";
import { useAgentContext } from "../../agent/hooks/AgentContext";
import { useClientAgentContext } from "../../fabrk/hooks/ClientAgentContext";
import { useRealtimeChat } from "./useRealtimeChat";

export default function VoiceRecorder() {
  const { isActive, startVoiceChat, stopVoiceChat } = useRealtimeChat();

  const { activeAgent } = useAgentContext();
  const { activeClientAgent } = useClientAgentContext();

  const handleStartVoiceChat = async () => {
    await startVoiceChat({
      voice: "shimmer",
      clientAgentId: activeClientAgent?.id as string,
      agentId: activeAgent?.id as string,
    });
  };

  return (
    <div className="voice-recorder">
      <button onClick={isActive ? stopVoiceChat : handleStartVoiceChat}>
        {isActive ? (
          <MicrophoneIcon className="h-6 w-6 text-red-500" />
        ) : (
          <MicrophoneIcon className="h-6 w-6 text-green-500" />
        )}
      </button>
    </div>
  );
}
