/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { UseMutateFunction, useMutation } from "react-query";

import { useNavigate } from "react-router-dom";
import { ActionRow } from "../../../api/action.type";
import { AgentRow } from "../../../api/agent.type";
import {
  createFabrkMessage,
  getMessageById,
  getSmsMessagesByClientAgent,
  sendFabrkSmsOrEmail,
} from "../../../api/message.api";
import {
  MessageRoleType,
  MessageRow,
  SendFabrkRequest,
} from "../../../api/message.type";
import { useEventStreaming } from "../../message/StreamMessageContext";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useAuthContext } from "./AuthContext";
import { useClientAgentContext } from "./ClientAgentContext";

export const MessageContextWrapper = (props: any) => {
  const { activeClientAgent } = useClientAgentContext();
  const navigate = useNavigate();

  const { companyId } = useAuthContext();
  const { startStreamingPersona, setText } = useEventStreaming();

  const [counter, setCounter] = useState(0);
  const [persona, setPersona] = useState<AgentRow | undefined>();

  const { setAlertProps } = useAlertContext();

  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState<MessageRow[]>([]);
  const [newMessage, setNewMessage] = useState<MessageRow | undefined>();

  const [action, setAction] = useState<ActionRow | undefined>();

  const [messageCreatedTime, setMessageCreatedTime] = useState<string | null>();

  function getUpdatedMessages() {
    if (activeClientAgent) {
      getMessages({ clientAgentId: activeClientAgent.id });
    }
  }

  const { mutate: createMessage, reset } = useMutation(createFabrkMessage, {
    onSuccess: async (res) => {
      if (res.generated_by !== "fabrk_app_realtime_transcript") {
        setNewMessage(res);
      }

      if (messages) {
        setMessages((prev) => [...(prev || []), res]);
      } else {
        setMessages([res]);
      }

      if (!activeClientAgent) {
        navigate(`/a/${res.agent_id}/t/${res.client_agent_id}`);
        setLoading(true);
        setNewMessage(res);
      }
    },
  });

  function handleCreateMessageFromRealtimeTranscript({
    content,
    agentId,
    role,
    clientAgentId,
  }: {
    content: string;
    agentId: string;
    role: MessageRoleType;
    clientAgentId: string;
  }) {
    createMessage({
      clientAgentId,
      content,
      agentId,
      role,
      source: "fabrk_app_realtime_transcript",
    });
  }

  function handleCreateMessage({
    content,
    fileStoreId,
    actionId,
    agentId,
  }: {
    content: string;
    agentId: string;
    fileStoreId?: string;
    actionId?: string;
  }) {
    if (!content) {
      setAlertProps({
        message: "Message cannot be empty",
        color: "red",
      });
      return;
    }

    setLoading(true);

    createMessage({
      agentId,
      companyId,
      content,
      source: "fabrk_app",
      ...(activeClientAgent?.client_id && {
        clientId: activeClientAgent.client_id,
      }),
      ...(fileStoreId && { fileStoreId }),
      ...(actionId && { actionId }),
    });
  }

  const [previousMessageCount, setPreviousMessageCount] = useState(0);
  const [urlToAutoPlay, setUrlToAutoPlay] = useState<string | null>(null);

  const retryCountRef = useRef(0); // Track retries

  function handleGetLastMessageRes(res: MessageRow) {
    const isAssistantMessage = res?.role === MessageRoleType.assistant;
    const userMessageGenerateByAudio = messages.find(
      (m) => m.role === MessageRoleType.user && m.generated_by === "app_audio",
    );

    if (
      res &&
      isAssistantMessage &&
      res.audio_url &&
      previousMessageCount !== messages.length
    ) {
      // Play audio for assistant response
      setUrlToAutoPlay(res.audio_url);
      setPreviousMessageCount(messages.length);
      getUpdatedMessages();

      // Reset retry count on success
      retryCountRef.current = 0;
    } else if (
      userMessageGenerateByAudio &&
      previousMessageCount !== messages.length &&
      retryCountRef.current < 10
    ) {
      // Retry logic
      retryCountRef.current++;

      setTimeout(() => {
        getLastAssistantMessage(res.id); // Trigger mutation
      }, 2000); // Retry every 2 seconds
    } else {
      // No further retries or processing
      setPreviousMessageCount(messages.length);
      getUpdatedMessages();
    }

    if (retryCountRef.current >= 10) {
      console.log("Max retry attempts reached.");
    }
  }

  const { mutate: getLastAssistantMessage } = useMutation(getMessageById, {
    onSuccess: async (res) => {
      if (res) {
        handleGetLastMessageRes(res);
      }
    },
  });

  const MAX_RETRIES = 5; // Set a cap on the number of retries
  let retryCount = 0; // Track the current number of retries

  const { mutate: getMessages } = useMutation(getSmsMessagesByClientAgent, {
    onSuccess: async (res) => {
      if (res) {
        setMessages(res);

        console.log(previousMessageCount, res.length);

        const lastMessage = res[res?.length - 1];

        const isAssistantMessage =
          lastMessage?.role === MessageRoleType.assistant;

        if (
          lastMessage &&
          isAssistantMessage &&
          previousMessageCount !== res.length
        ) {
          getLastAssistantMessage(lastMessage.id);
        }

        if (counter > 0 && persona && isAssistantMessage) {
          startStreamingPersona(
            activeClientAgent?.id as string,
            persona?.id as string,
          );
        }

        if (!isAssistantMessage) {
          if (retryCount < MAX_RETRIES) {
            retryCount++;
            getUpdatedMessages();
          }
        } else {
          setText("");
        }
      } else {
        setLoading(false);
      }
    },
    onError(error: Error) {
      console.log(error);
    },
    onSettled: () => {
      setLoading(false);
      setMessageCreatedTime(null);
    },
  });

  const { mutate: sendMessage } = useMutation(sendFabrkSmsOrEmail, {
    onSuccess: async (res) => {
      setAlertProps({
        message: "Message sent. You will receive a message response shortly.",
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        message: "Failed to send message" + error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (activeClientAgent) {
      getUpdatedMessages();
    } else {
      setMessages([]);
    }
  }, [activeClientAgent]);

  useEffect(() => {
    if (action && activeClientAgent) {
      if (action.content) {
        handleCreateMessage({
          content: action.content,
          actionId: action?.id,
          agentId: action.agent_id,
        });
      } else if (action?.sms_message?.content) {
        handleCreateMessage({
          content: action?.sms_message?.content,
          actionId: action?.id,
          agentId: action.agent_id,
        });
      }

      setAction(undefined);
    }
  }, [action, activeClientAgent]);

  const value = {
    messages,
    handleCreateMessage,
    loading,
    setMessages,
    sendMessage,
    newMessage,
    messageCreatedTime,
    reset,
    getUpdatedMessages,
    setNewMessage,
    setCounter,
    counter,
    persona,
    setPersona,
    setAction,
    urlToAutoPlay,
    setUrlToAutoPlay,
    handleCreateMessageFromRealtimeTranscript,
  };

  return (
    <MessageContext.Provider value={value}>
      {props.children}
    </MessageContext.Provider>
  );
};

export const MessageContext = createContext({
  messages: {} as MessageRow[] | undefined,
  handleCreateMessage: {} as ({
    content,
    fileStoreId,
    actionId,
    agentId,
  }: {
    content: string;
    agentId: string;
    fileStoreId?: string;
    actionId?: string;
  }) => void,
  loading: false,
  setMessages: {} as React.Dispatch<React.SetStateAction<MessageRow[]>>,
  sendMessage: {} as UseMutateFunction<any, Error, SendFabrkRequest, unknown>,
  messageCreatedTime: "" as string | null | undefined,
  newMessage: {} as MessageRow | undefined,
  reset: {} as () => void,
  getUpdatedMessages: {} as () => void,
  setNewMessage: {} as React.Dispatch<
    React.SetStateAction<MessageRow | undefined>
  >,
  setCounter: {} as React.Dispatch<React.SetStateAction<number>>,
  counter: 0,
  persona: {} as AgentRow | undefined,
  setPersona: {} as React.Dispatch<React.SetStateAction<AgentRow | undefined>>,
  setAction: {} as React.Dispatch<React.SetStateAction<ActionRow | undefined>>,
  urlToAutoPlay: "" as string | null,
  setUrlToAutoPlay: {} as React.Dispatch<React.SetStateAction<string | null>>,
  handleCreateMessageFromRealtimeTranscript: {} as ({
    content,
    agentId,
    role,
  }: {
    content: string;
    agentId: string;
    role: MessageRoleType;
    clientAgentId: string;
  }) => void,
});

export const useMessageContext = () => useContext(MessageContext);
